import React, { useState,useEffect } from 'react';
import Topbar from '../../superadmin/Layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, InputAdornment, IconButton, Menu, Typography,
  CircularProgress
} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import instance from '../../utils/Api';
// import view from '../../superadmin/assets/icons/view-icon.svg';
// import edit from '../../superadmin/assets/icons/add-icon.svg';
import { useGlobalContext } from '../../Contex'; // Adjust the path


const Schoollist = () => {
  const { anchorEl, setAnchorEl } = useGlobalContext();   //filter
  const { loading, setLoading } = useGlobalContext();  // State to handle loading
  const [page, setPage] = useState(0);
  const [schools, setSchools] = useState([]);
  const rowsPerPage = 10; // Fixed rows per page
  const startIndex = (page) * rowsPerPage;  // Calculate the start and end index for pagination
  const open = Boolean(anchorEl);


  //filter function open menu
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //filter function close menu
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };

  //handle change
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Adjust to be 1-based
  };

  // Data slicing based on current page and rowsPerPage
  const paginatedSchools = schools.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  //fetch fetchSchools
  const fetchSchools = async () => {
    try {
      const response = await instance.get('Schools/getSchools');
      setSchools(response.data.data);
    } catch (error) {
      console.error('Error fetching Schools:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchools();
    setLoading(true);
  }, []);
  return (
    <React.Fragment>
      <Topbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (<section className='main'>
        <div className='employees-text'>
          <Typography variant='h5'>School List</Typography>
        </div>
        <div className='employees-main'>
          <div className='employees-content'>
            <div className='search-options'>
              <TextField
                placeholder='Search'
                type='search'
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className='pagination-options'>
              <div className='pagination-animation'></div>
              <TablePagination
                component="div"
                count={schools?.length}
                rowsPerPage={rowsPerPage}
                page={page} // Adjust to be 0-based for MUI TablePagination
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) => (
                  <React.Fragment>
                    {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                    <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                  </React.Fragment>
                )}
              />
              <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter schoollist</Typography>
                    <CloseIcon onClick={handleClose1} />
                  </div>
                </Menu>
              </div>
            </div>
          </div>
          <div className='employee-table'>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className='employee-name'>
                        S.NO
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name'>
                        Name
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name'>
                        School Name
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name' >
                        Email
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name' >
                        Mobile Number
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name' >
                        Status
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <div className='employee-name' >
                        Action
                      </div>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedSchools.length > 0 ? (
                    paginatedSchools.map((school, index) => (
                      <TableRow tabIndex={-1} key={school._id}>
                        <TableCell component="th" scope="row">
                          {startIndex + index + 1}
                        </TableCell>
                        <TableCell align='left'>{school.name}</TableCell>
                        <TableCell align="left">{school?.School}</TableCell>
                        <TableCell align="left">{school?.email}</TableCell>
                        <TableCell align="left">{school.mobile_no}</TableCell>
                        <TableCell align='left'><span style={{ backgroundColor: school.status === true ? 'rgb(229, 252, 240)' : 'rgb(255, 232, 232)', color: school.status === true ? 'rgb(0, 166, 81)' : 'rgb(235, 87, 87)', padding: "5px 7px", borderRadius: "3px"}}>{school.status === true ? "Active" : "Inactive"}</span> </TableCell>
                        {/* <TableCell align="left">
                          <div className='view'>
                            <div><img src={view} alt="view" /></div>
                            <div> <img src={edit} alt="edit" /></div>
                          </div>
                        </TableCell> */}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography>No Data Found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </section>
      )}

    </React.Fragment>
  )
}

export default Schoollist