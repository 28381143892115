import React, { createContext, useState, useContext } from 'react';

// Create the context
const ButtonContext = createContext();

// Create a provider component
export const ContexProvider = ({ children }) => {
    const [buttondisable, setButtondisable] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);//status popup
    const [loading, setLoading] = useState(true);  // State to handle loading
    const [anchorEl, setAnchorEl] = useState(null);   //filter
    const [collapsed, setCollapsed] = useState(false);
    return (
        <ButtonContext.Provider value={{ buttondisable, setButtondisable, openDialog, setOpenDialog, openStatusDialog, setOpenStatusDialog, loading, setLoading, anchorEl, setAnchorEl, collapsed, setCollapsed }}>
            {children}
        </ButtonContext.Provider>
    );
};

// Custom hook to use the buttondisable context
export const useGlobalContext = () => useContext(ButtonContext);
