import React from 'react';
import { Button } from '@mui/material'
import '../../superadmin/styles/layouts/common_topbar.css';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const navigate = useNavigate();

  const handlelogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate('/superadmin');
    window.location.reload();
  }
  return (
    <section className='topbar'>
         <div className='topbar-main'>
             <Button onClick={handlelogout}>Logout</Button>
         </div>
    </section>
  )
}

export default Topbar