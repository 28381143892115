import React from 'react';
import { Link, Outlet } from "react-router-dom";
import { List, ListItem, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { useLocation } from "react-router-dom";
import logo from '../../superadmin/assets/logo.svg';
import '../../superadmin/styles/layouts/common_sidebar.css';
// import dashboard_icon from '../../superadmin/assets/icons/dashboard-icon.svg';
// import schoollist_icon from '../../superadmin/assets/icons/scholllist-icon.svg';
import dashboard_icon from '../../superadmin/assets/icons/dashboard.png';
import school_icon from '../../superadmin/assets/icons/school-2.png';
import admin_icon from '../../superadmin/assets/icons/admins.png';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useGlobalContext } from '../../Contex';



const Sidebar = () => {
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const { collapsed, setCollapsed } = useGlobalContext();

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  return (
    <React.Fragment>
      <Grid container >
        <Grid item sm={1.4} md={collapsed ? 1 : 2.5} lg={collapsed ? 0.7 : 2} className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
          <div className='sidebar-content'>
            <div className='sidebar-logo'>
              <img src={logo} alt='logo' />
              {!collapsed && <Typography>Anganwadi</Typography>}
            </div>
            <div className='toggle-button'>
              <IconButton
                onClick={handleToggleSidebar}
              >
                {collapsed ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>
            <List>
              <Link to="/superadmin/dashboard">
                <Tooltip title="Dashboard" placement="right" arrow disableHoverListener={!collapsed}>
                  <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "dashboard" ? "active" : ""}>
                    <img src={dashboard_icon} alt='dashboard icon' />
                    {!collapsed && <span>Dashboard</span>}
                  </ListItem>
                </Tooltip>
              </Link>
              <Link to="/superadmin/admins">
                <Tooltip title="Admins" placement="right" arrow disableHoverListener={!collapsed}>
                  <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "admins" ? "active" : ""}>
                    <img src={admin_icon} alt='admin icon' />
                    {!collapsed && <span>Admin's</span>}
                  </ListItem>
                </Tooltip>
              </Link>
              <Link to="/superadmin/schoollist">
                <Tooltip title="Admins" placement="right" arrow disableHoverListener={!collapsed}>
                  <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "schoollist" ? "active" : ""}>
                    <img src={school_icon} alt='schoollist icon' />
                    {!collapsed && <span>School List</span>}
                  </ListItem>
                </Tooltip>
              </Link>
              {/* <Link to="/superadmin/anganwadi">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "anganwadi"  ? "active" : ""}>
                  <img src={schoollist_icon} alt='schoollist icon' />
                  <span>Anganwadi's</span>
                </ListItem>
              </Link> */}
            </List>
          </div>
        </Grid>
        <Grid item sm={10.6} md={collapsed ? 11 : 9.5} lg={collapsed ? 11.3 : 10}>
          <Outlet />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Sidebar;