import React, { useState, useEffect } from 'react';
import Topbar from '../../superadmin/Layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import { Typography, Grid } from '@mui/material';
import admins_icon from '../../superadmin/assets/icons/admins-icon.svg';
import school_icon from '../../superadmin/assets/icons/schools-icon.svg';
import students_icon from '../../superadmin/assets/icons/students-icon.svg';
import addadmin_icon from '../../superadmin/assets/icons/add-admin-icon.svg';
import instance from '../../utils/Api';


const Dashboard = () => {
    const [admins, setAdmins] = useState([]);
    const [schools, setSchools] = useState([]);

    // Fetch admins 
    const fetchAdmins = async () => {
        try {
            const response = await instance.get('Admins/getAdmins');
            setAdmins(response.data.data);
        } catch (error) {
            console.error('Error fetching admins:', error.message);
        }
    };


    // Fetch Schools 
    const fetchSchools = async () => {
        try {
            const response = await instance.get('Schools/getSchools');
            setSchools(response.data.data);
        } catch (error) {
            console.error('Error fetching Schools:', error.message);
        }
    };

    useEffect(() => {
        fetchAdmins();
        fetchSchools()
    }, []);

    return (
        <React.Fragment>
            <Topbar />
            <section className='main-1'>
                <div className='dashboard-content-text'>
                    <Typography variant='h2'>Welcome to your dashboard, Mr.Super Admin</Typography>
                    <Typography variant='h5'>Anganwadi@vibhotech.com</Typography>
                </div>
                <section className='dashboard-count'>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box'>
                                <img src={admins_icon} alt='admins_icon' />
                                <Typography>Total Admis</Typography>
                                <Typography variant='h6'>{admins.length}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box'>
                                <img src={school_icon} alt='school_icon' />
                                <Typography>Total Schools</Typography>
                                <Typography variant='h6'>{schools.length}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box'>
                                <img src={students_icon} alt='students_icon' />
                                <Typography>Total Students</Typography>
                                <Typography variant='h6'>80</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </section>
                <section className='admin-section'>
                    <div className='admin-conntent'>
                        <ul>
                            <li><img src={addadmin_icon} alt='addadmin_icon' /></li>
                            <li>
                                <Typography variant='h6'>Add Admin</Typography>
                                <Typography>Create rich course content and coaching products for your students.
                                    When you give them a pricing plan, they’ll appear on your site!</Typography>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </React.Fragment>
    )
}

export default Dashboard