import React, { useState,useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//superadmin
import Login from './superadmin/pages/Login';
import Sidebar from './superadmin/Layouts/Sidebar';
import Dashboard from './superadmin/pages/Dashboard';
import Admins from './superadmin/pages/Admins';
import Schoollist from './superadmin/pages/School_list';
// import Anganwadi from './superadmin/pages/Anganwadi';
import Forgotpassword from './superadmin/pages/Forgotpassword';
//admin
import Adminlogin from './admin/pages/Login';
import Adminsidebar from './admin/layouts/Sidebar';
import Admindashboard from './admin/pages/Dasboard';
import Adminschoollist from './admin/pages/School_list';
import AdminForgotpassword from './admin/pages/Forgotpassword';
import AdminInventory from './admin/pages/Inventory';
import AdminTransaction from './admin/pages/Transaction';
import AdminProducts from './admin/pages/Products';
import AdminStudent from './admin/pages/Student';




function AdminRoutes() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Adminsidebar />} >
          <Route path='/dashboard' element={<Admindashboard />} />
          <Route path='/schoollist' element={<Adminschoollist />} />
          <Route path='/inventory' element={<AdminInventory />} />
          <Route path='/transaction' element={<AdminTransaction />} />
          <Route path='/student' element={<AdminStudent />} />
          <Route path='/products' element={<AdminProducts />} />
        </Route>
      </Routes>
    </>
  )
}

function SuperadminRoutes() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Sidebar />} >
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/admins' element={<Admins />} />
          <Route path='/schoollist' element={<Schoollist />} />
        </Route>
      </Routes>
    </>
  )
}


function App() {
  const [triggerRefresh, setTriggerRefresh] = useState();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  // function isShortcutKey(e, keyCode) {
  //   // Check for Ctrl + Shift + Key or Cmd + Option + Key
  //   return (
  //     (e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)) ||
  //     (e.metaKey && e.altKey && e.keyCode === keyCode.charCodeAt(0))
  //   );
  // }

  // useEffect(() => {
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //   });

  //   document.onkeydown = (e) => {
  //     // Disable F12, Ctrl + Shift + I/J/C, Cmd + Option + I/J/C, Ctrl + U
  //     if (
  //       e.keyCode === 123 ||
  //       isShortcutKey(e, "I") ||
  //       isShortcutKey(e, "J") ||
  //       isShortcutKey(e, "C") ||
  //       (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) ||
  //       (e.metaKey && e.keyCode === "U".charCodeAt(0))
  //     ) {
  //       e.preventDefault();
  //       return false;
  //     }
  //   };
  // }, []);

  // function selfDestruct() {
  //   document.body.innerHTML = ''; // Clear the page content
  // }

  // if (window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100) {
  //   selfDestruct();
  // }

  // window.onresize = function () {
  //   if (window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100) {
  //     selfDestruct();
  //   }
  // };

  let authenticated = false;

  if (token) {
    authenticated = true;
  }

  if (!authenticated) {
    return (
      <>
        <Routes>
          <Route path='/superadmin' element={<Login setTriggerRefresh={setTriggerRefresh} />} />
          <Route path='/superadmin/forgotpassword' element={<Forgotpassword />} />
          <Route path='/admin' element={<Adminlogin setTriggerRefresh={setTriggerRefresh} />} />
          <Route path='/admin/forgotpassword' element={<AdminForgotpassword />} />
          <Route path="*" element={<Navigate to={"/superadmin"} />} />

        </Routes>
      </>

    )
  } else if (role === "SuperAdmin" && authenticated) {
    return (
      <>
        <Routes>
          <Route path='/' element={<Navigate to={"superadmin/dashboard"} />} />
          <Route path="/superadmin/*" element={<SuperadminRoutes />} />
        </Routes>
      </>
    )
  } else if (role === "Admin" && authenticated) {
    return (
      <>
        <Routes>
          <Route path='/' element={<Navigate to={"admin/dashboard"} />} />
          <Route path="/admin/*" element={<AdminRoutes />} />
        </Routes>
      </>
    )
  }

}

export default App;

