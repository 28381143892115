import React, { useState, useEffect } from 'react';
import '../../superadmin/styles/pages/dashboard.css';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography,
  Button, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
// import SearchIcon from "@mui/icons-material/Search";
// import CloseIcon from '@mui/icons-material/Close';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Topbar from '../../admin/layouts/Topbar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import instance from '../../utils/Api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Transaction = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const schoolId = searchParams.get('schoolId')
  const schoolname = searchParams.get('schoolname')
  const [page, setPage] = useState(1);
  // const open = Boolean(anchorEl);
  // const todayDate = dayjs().startOf('day');  // Get the start of today (midnight)
  const [transaction, setTransaction] = useState([])
  const [startDate, setStartDate] = useState(dayjs()); // Initialize with the current date

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // //filter function open menu
  // const handleClick1 = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // //filter function close menu
  // const handleClose1 = () => {
  //   setAnchorEl(null);
  // };

  //fetch products
  const fetchTransaction = async () => {
    try {
      const response = await instance.get(`Inventory/getSchoolTransactions?school_id=${schoolId}&date=${startDate.format('YYYY/MM/DD')}`);
      setTransaction(response.data.data);
    } catch (error) {
      console.error('Error fetching Transaction:', error.message);
    }
  };
  useEffect(() => {
    fetchTransaction();
  }, []);

  // Utility function to group transactions by full 'createdAt' timestamp (Date + Time)
  const groupByDateTime = (transactions) => {
    return transactions.reduce((acc, trans) => {
      const dateTime = new Date(trans.createdAt).toLocaleString(); // Full Date and Time string
      if (!acc[dateTime]) {
        acc[dateTime] = [];
      }
      acc[dateTime].push(trans);
      return acc;
    }, {});
  };

  return (
    <React.Fragment>
      <Topbar />
      <section className='main'>
        <div className='employees-text inventory-backtn'>
          <Typography variant='h5'>Transaction</Typography>
          <Button onClick={() => navigate('/admin/schoollist')}><KeyboardBackspaceIcon />Back</Button>
          {/* <Button variant="outlined" >Download Transaction</Button> */}
        </div>
        <div className='employees-main transaction-main'>
          <div className='employees-content transaction-menu'>
            <div className='search-options'>
              {/* <TextField
                placeholder='Search'
                type='search'
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              /> */}
              <Typography variant='h5'>
                School Name : <span>{schoolname}</span>
                {/* <div className='employee-checkin-date-1'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={startDate} // Use value prop with the current date
                      onChange={(newValue) => setStartDate(newValue)} // Update the state when a new date is selected
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </div> */}
              </Typography>
            </div>
            <div className='pagination-options table-pagination'>
              {/* <TablePagination
                component="div"
                count={10}
                rowsPerPage={1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              /> */}
              {/* <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter Transaction</Typography>
                    <CloseIcon onClick={handleClose1} />
                  </div>
                  <div className='filter-status'>
                    <div className='employee-checkin-date'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          // value={startDate}
                          // onChange={(newValue) => setStartDate(newValue)}
                          format="DD/MM/YYYY"
                          defaultValue={dayjs().startOf('month')}  // This sets the default value to the end of the current month
                        />
                        <DatePicker
                          label="End Date"
                          format="DD/MM/YYYY"
                          defaultValue={dayjs().endOf('month')}  // This sets the default value to the end of the current month
                        // value={endDate}
                        // onChange={(newValue) => setEndDate(newValue)}
                        />

                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='apply_fliters'>
                    <Button >Apply Fliter</Button>
                  </div>
                </Menu>
              </div> */}
            </div>
          </div>
          <div className='employee-table tranaction-accordion'>
            {Object.entries(groupByDateTime(transaction)).map(([dateTime, dateTimeTransactions]) => (
              <Accordion key={dateTime}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${dateTime}-content`}
                  id={`panel-${dateTime}-header`}
                >
                  Created Date :  <span>{dateTime}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table aria-label="transaction table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div className='employee-name'>
                              S.NO
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='employee-name'>
                              Product Name
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='employee-name'>
                              Previous Stock
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='employee-name'>
                              Latest Stock
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='employee-name'>
                              Total Quantity
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dateTimeTransactions.map((trans, transIndex) =>
                          trans.products.map((product, index) => {
                            const prevProduct = trans.prev_products.find(p => p.product_id === product.product_id);
                            const previousQuantity = prevProduct ? prevProduct.quantity : 0;
                            const latestQuantity = product.quantity;
                            return (
                              <TableRow key={product._id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{product.product_name}</TableCell>
                                <TableCell>{previousQuantity}</TableCell>
                                <TableCell>{latestQuantity}</TableCell>
                                <TableCell>{previousQuantity + latestQuantity}</TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Transaction