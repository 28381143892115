import React from 'react';
import '../../superadmin/styles/pages/dashboard.css';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from '../../utils/Api';
import { useGlobalContext } from '../../Contex'; // Adjust the path


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

//changestatusSchools
const Statusschool = ({ open, handleClose, schools, setAddschools }) => {
    const { buttondisable, setButtondisable } = useGlobalContext();
    //change status
    const changestatusSchools = async () => {
        setButtondisable(true);
        try {
            const response = await instance.patch(`Schools/ToggleStatus?author_id=${schools._id}`);
            if (response.data.success === true) {
                toast.success(response.data.message);
                handleClose()
                setAddschools(true);// Trigger refresh after changing the school status
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
            console.log(error.response);
            setButtondisable(false);
        }
    }
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="admin-admins"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Change School Status
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography>
                        Are you sure you want to change the status for <strong>{schools?.name}</strong> ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className="cancel-btn" onClick={handleClose}>Cancel</Button>
                    <Button className="add-btn" disabled={buttondisable} onClick={changestatusSchools}>Yes</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Statusschool;