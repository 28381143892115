import React from 'react';
import { Link, Outlet } from "react-router-dom";
import { List, ListItem, Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { useLocation } from "react-router-dom";
import logo from '../../superadmin/assets/logo.svg';
import '../../superadmin/styles/layouts/common_sidebar.css';
import product_icon from '../../superadmin/assets/icons/product-icon.png';
// import transaction_icon from '../../superadmin/assets/icons/transaction-icon.png';
import dashboard_icon from '../../superadmin/assets/icons/dashboard.png';
import school_icon from '../../superadmin/assets/icons/school-2.png';
// import inventory_icon from '../../superadmin/assets/icons/inventory.png';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useGlobalContext } from '../../Contex';



const Sidebar = () => {
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const { collapsed, setCollapsed } = useGlobalContext();

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  return (
    <React.Fragment>
      <Grid container >
        <Grid item sm={1.4} md={collapsed ? 1 : 2.5} lg={collapsed ? 0.7 : 2} className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
          <div className='sidebar-content'>
            <div className='sidebar-logo '>
              <img src={logo} alt='logo' />
              {!collapsed && <Typography>Anganwadi</Typography>}
            </div>
            <div className='toggle-button'>
              <IconButton
                onClick={handleToggleSidebar}
              >
                {collapsed ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>
            <List>
              <Link to="/admin/dashboard">
                <Tooltip title="Dashboard" placement="right" arrow disableHoverListener={!collapsed}>
                  <ListItem className={splitLocation[1] === "admin" && splitLocation[2] === "dashboard" ? "active" : ""}>
                    <img src={dashboard_icon} alt='dashboard icon' />
                    {!collapsed && <span>Dashboard</span>}
                  </ListItem>
                </Tooltip>
              </Link>
              <Link to="/admin/schoollist">
                <Tooltip title="School List" placement="right" arrow disableHoverListener={!collapsed}>
                  <ListItem className={(splitLocation[1] === "admin" && splitLocation[2] === "schoollist") || (splitLocation[1] === "admin" && splitLocation[2] === "inventory") || (splitLocation[1] === "admin" && splitLocation[2] === "transaction") || (splitLocation[1] === "admin" && splitLocation[2] === "student") ? "active" : ""}>
                    <img src={school_icon} alt='schoollist icon' />
                    {!collapsed && <span>School List</span>}
                  </ListItem>
                </Tooltip>
              </Link>
              <Link to="/admin/products">
                <Tooltip title="Products" placement="right" arrow disableHoverListener={!collapsed}>
                  <ListItem className={splitLocation[1] === "admin" && splitLocation[2] === "products" ? "active" : ""}>
                    <img src={product_icon} alt='product icon' />
                    {!collapsed && <span>Products</span>}
                  </ListItem>
                </Tooltip>
              </Link>
              {/* <Link to="/admin/inventory">
                <ListItem className={splitLocation[1] === "admin" && splitLocation[2] === "inventory" ? "active" : ""}>
                  <img src={inventory_icon} alt='inventory icon' />
                  <span>Inventory</span>
                </ListItem>
              </Link> */}
              {/* <Link to="/admin/admins">
                <ListItem className={splitLocation[1] === "admin" && splitLocation[2] === "admins"  ? "active" : ""}>
                  <img src={dashboard_icon} alt='dashboard icon' />
                  <span>Admin's</span>
                </ListItem>
              </Link> 
              */}
              {/* <Link to="/admin/transaction">
                <ListItem className={splitLocation[1] === "admin" && splitLocation[2] === "transaction" ? "active" : ""}>
                  <img src={transaction_icon} alt='transaction icon' />
                  <span>Transaction</span>
                </ListItem>
              </Link> */}
            </List>
          </div>
        </Grid>
        <Grid item sm={10.6} md={collapsed ? 11 : 9.5} lg={collapsed ? 11.3 : 10}>
          <Outlet />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Sidebar;