import React, { useState } from 'react';
import '../../superadmin/styles/pages/auth.css';
import { Button, TextField, IconButton, InputAdornment, Typography, Grid, FormHelperText } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EastIcon from '@mui/icons-material/East';
import { useNavigate, Link } from 'react-router-dom';
import instance, { updateToken } from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../Contex'; // Adjust the path

const Login = ({ setTriggerRefresh }) => {
  const navigate = useNavigate();
  const { buttondisable, setButtondisable } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword)
  }

  //validation for loginsuperadmin
  const validateForm = () => {
    let isValid = true;

    //email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailRegex.test(email.toString().trim())) {
      setEmailError("Invalid email format");
      isValid = false
    } else {
      setEmailError("")
    }

    // password validation
    if (!password) {
      setPasswordError("password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  };

  //function for login
  const loginsuperadmin = async () => {
    if (!validateForm()) {
      return;
    }
    setButtondisable(true);
    try {
      const response = await instance.post(`SuperAdmin/login`, {
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.success === true) {
        toast.success(response.data.message)
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role);
        updateToken(response.data.token)
        navigate('/superadmin/dashboard');
        setTriggerRefresh((prev) => prev + 1);
        setButtondisable(false);
      } else {
        toast.error(response.data.message);
        setButtondisable(false);
      }
    }
    catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      setButtondisable(false);
    }
  }

  const handlekeydown = (event) => {
    if (event.key === "Enter") {
      loginsuperadmin();
    }
  }
  return (
    <section className='login'>
      <div className='login-main'>
        <div className='login-content'>
          <Grid container direction="column" spacing={2.5}>
            <Grid item>
              <Typography variant='h4'>Welcome, Log into you account !</Typography>
              <Typography>It is our great pleasure to have you Login !</Typography>
            </Grid>
            <Grid item>
              <TextField
                type="email"
                label="Email"
                placeholder="Enter Your Email"
                variant="outlined"
                required
                fullWidth
                value={email}
                onChange={(e) => { setemail(e.target.value); setEmailError('') }}
                error={Boolean(emailError)}
              />
              <FormHelperText error={Boolean(emailError)}>
                {emailError}
              </FormHelperText>
            </Grid>
            <Grid item>
              <TextField
                label='Password'
                variant="outlined"
                placeholder='Enter Your Password'
                required
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => { setpassword(e.target.value); setPasswordError('') }}
                error={Boolean(passwordError)}
                onKeyDown={handlekeydown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormHelperText error={Boolean(passwordError)}>
                {passwordError}
              </FormHelperText>
            </Grid>
            <Grid item>
              <Link to="/superadmin/forgotpassword" className='forgot-text'>
                Forgot Password
              </Link>
            </Grid>
            <Grid item>
              <div className='login-logo'>
                <Button disabled={buttondisable} onClick={loginsuperadmin}>Login</Button>
              </div>
            </Grid>
            <Grid item>
              <Link to="/admin" className='forgot-text-1'>
                Login As Admin
                <EastIcon />
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  )
}

export default Login