import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, TextField, Button, MenuItem, FormControl, InputLabel, Select, Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import instance from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../Contex'; // Adjust the path

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Addproduct = ({ openDialog, setOpenDialog, setAddproducts }) => {
    const { buttondisable, setButtondisable } = useGlobalContext();
    const [productname, setProductName] = useState();
    const [unit, setUnit] = useState();
    const [ageLimits, setAgeLimits] = useState([]);
    // Your existing options, structured similarly to MenuItem
    const ageOptions = [
        "7 months to 3 years",
        "3 to 6 years",
    ];

    //function popup close
    const handleClose = () => {
        setOpenDialog(false);
        //empty values
        setProductName('');
        setUnit('');
        setAgeLimits([]);
    }
    //add products api call
    const handleAddProduct = async () => {
        if (!productname || !unit) {
            toast.error('Please fill all the required fields');
            return;
        }
        setButtondisable(true);
        try {
            const response = await instance.post('Inventory/AddProduct', {
                product_name: productname,
                unit,
                age_limits: ageLimits,
            });
            if (response.data.success === true) {
                toast.success(response.data.message)
                handleClose();
                setAddproducts(true);// Trigger refresh after create schools
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            // Check if the error is due to a duplicate product name
            if (error?.response?.data?.error?.includes('E11000 duplicate key error')) {
                toast.error(`Product name "${productname}" already exists. Please choose a different name.`);
            } else {
                toast.error(error?.response?.data?.error || 'An unexpected error occurred');
            }
            console.log(error.response);
            setButtondisable(false);
        }
    }



    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                className="admin-admins"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Products
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <section>
                        <div className='add-admin'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the product name'
                                            label='Product Name'
                                            type='text'
                                            fullWidth
                                            required
                                            value={productname}
                                            onChange={(e) => setProductName(e.target.value)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Units</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Units"
                                                value={unit}
                                                onChange={(e) => setUnit(e.target.value)}
                                            >
                                                <MenuItem value="Liters">Liters</MenuItem>
                                                <MenuItem value="Kilograms">Kilograms</MenuItem>
                                                <MenuItem value="Pounds">Pounds</MenuItem>
                                                <MenuItem value="Ounces">Ounces</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled-1'>
                                        <Autocomplete
                                            multiple // This enables multiple selection
                                            options={ageOptions}
                                            value={ageLimits}
                                            onChange={(event, newValue) => {
                                                setAgeLimits(newValue); // newValue will be an array of selected values
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Age Limits" variant="outlined" />
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="cancel-btn"
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="add-btn" disabled={buttondisable} onClick={handleAddProduct}>
                        Add
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Addproduct