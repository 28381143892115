import React, { useState } from 'react';
import '../../superadmin/styles/pages/auth.css';
import { Button, TextField, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import instance from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import { useGlobalContext } from '../../Contex'; // Adjust the path

const Forgotpassword = () => {
    const { buttondisable, setButtondisable } = useGlobalContext();
    const navigate = useNavigate();
    const [email, setemail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [sendotp, setsendotp] = useState(false);

    //SendOTP functionality for superadmin
    const SendOTP = async () => {
        if(!email){
            toast.error('Email is required')
            return
        }
        setButtondisable(true);
        try {
            const response = await instance.get(`SuperAdmin/SendOtp?email=${email}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.success === true) {
                toast.success(response.data.message);
                setsendotp(true);
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error.response);
            setButtondisable(false);
        }
    }

    //Resetpassword functionality for superadmin
    const Resetpassword = async () => {
        if(!email || !newPassword ||!confirmPassword ||!otp){
            toast.error('Please fill all the required fields');
            return
        }
        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }
        setButtondisable(true);
        try {
            const response = await instance.patch("SuperAdmin/ResetPasswordOtp", {
                email,
                newPassword,
                confirmPassword,
                otp,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.success === true) {
                toast.success(response.data.message);
                setsendotp(false);
                navigate("/superadmin");
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error.response);
            setButtondisable(false);
        }
    }
    return (
        <section className='login'>
            <div className='login-main'>
                <div className='login-content'>
                    <Grid container direction="column" spacing={2}>
                        <Typography variant='h5'>Kindly provide a valid Email address !</Typography>
                        <Grid item>
                            <TextField
                                type="email"
                                label="Email"
                                placeholder="Enter Your Email"
                                variant="outlined"
                                required
                                fullWidth
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                disabled={sendotp}
                            />

                        </Grid>
                        
                        {sendotp ?
                            <>
                                <Grid item>
                                    <TextField
                                        label='New Password'
                                        variant="outlined"
                                        placeholder='Enter Your NewPassword'
                                        required
                                        fullWidth
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) =>setNewPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label='Confirm Password'
                                        variant="outlined"
                                        placeholder='Enter Your ConfirmPassword'
                                        required
                                        fullWidth
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) =>setConfirmPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                        <div className="send-otp-number">
                                            <OtpInput
                                                className='input'
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props}  />}

                                            />
                                        </div>
                                    </Grid>
                            </>
                            : null}
                        {sendotp ?
                            (<Grid item>
                                <div className="login-logo login-logo_2">
                                    <Button disabled={buttondisable} onClick={Resetpassword}>Verify OTP</Button>
                                </div>
                            </Grid>)
                            :
                            (<Grid item>
                                <div className='login-logo'>
                                    <Button disabled={buttondisable} onClick={SendOTP}>Submit</Button>
                                </div>
                            </Grid>)}
                    </Grid>
                </div>
            </div>
        </section>
    )
}

export default Forgotpassword;