import React from 'react';
import Topbar from '../../admin/layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import { Typography, Grid } from '@mui/material';
import anganwadi_icon from '../../superadmin/assets/icons/add-school.svg'
import addanganvadi_icon from '../../superadmin/assets/icons/add-anganwadi-icon.svg';
import totalschools_icon from '../../superadmin/assets/icons/dashboard-school-icon.svg';
import addanganwai_icon from '../../superadmin/assets/icons/total-anganwadi-icon.svg';
import totalstudents_icon from '../../superadmin/assets/icons/total-students-icon.svg';


const Dashboard = () => {
    return (
        <React.Fragment>
            <Topbar />
            <section className='main-1'>
                <div className='dashboard-content-text'>
                    <Typography variant='h2'>Welcome to your dashboard, Mr.Admin</Typography>
                    <Typography variant='h5'>Anganwadi@vibhotech.com</Typography>
                </div>
                <section className='dashboard-count'>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box'>
                                <img src={totalschools_icon} alt='total_schools' />
                                <Typography>Total Schools</Typography>
                                <Typography variant='h6'>5</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box'>
                                <img src={addanganwai_icon} alt='addanganwai_icon' />
                                <Typography>Total Stdents</Typography>
                                <Typography variant='h6'>10</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box'>
                                <img src={totalstudents_icon} alt='totalstudents_icon' />
                                <Typography>Total Products</Typography>
                                <Typography variant='h6'>09</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </section>
                <section className='admin-section'>
                    <div className='admin-conntent'>
                        <ul>
                            <li><img src={addanganvadi_icon} alt='addanganvadi_icon'/></li>
                            <li>
                                <Typography variant='h6'>Add Schools</Typography>                                
                                <Typography>Create rich course content and coaching products for your students.
                                When you give them a pricing plan, they’ll appear on your site!</Typography>    
                            </li>
                        </ul>
                    </div>
                    <div className='admin-conntent admin-schools'>
                        <ul>
                            <li><img src={anganwadi_icon} alt='addschool_icon'/></li>
                            <li>
                                <Typography variant='h6'>Add Anganwadi</Typography>                                
                                <Typography>Create rich course content and coaching products for your students.
                                When you give them a pricing plan, they’ll appear on your site!</Typography>    
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </React.Fragment>
    )
}

export default Dashboard