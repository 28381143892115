import React, { useState, useEffect } from 'react';
import Topbar from '../../admin/layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import { Typography, Grid, TextField, Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import instance from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../Contex'; // Adjust the path


const Inventory = () => {
    const [searchParams] = useSearchParams();
    const schoolId = searchParams.get('schoolId')
    const schoolname = searchParams.get('schoolname')
    const navigate = useNavigate();
    const [quantities, setQuantities] = useState([]); // Array to store quantities for each item
    const [inventory, setInventory] = useState([])
    const { buttondisable, setButtondisable } = useGlobalContext();

    // Function to handle increment
    const handleIncrement = (index) => {
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = (newQuantities[index] || 0) + 1;
            return newQuantities;
        });
    };

    // Function to handle decrement
    const handleDecrement = (index) => {
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = (newQuantities[index] || 0) > 0 ? newQuantities[index] - 1 : 0;
            return newQuantities;
        });
    };

    //fetch products
    const fetchInventory = async () => {
        try {
            const response = await instance.get(`Inventory/getInventory?school_id=${schoolId}`);
            setInventory(response.data.data);
            //empty quantity values
            setQuantities('');

        } catch (error) {
            console.error('Error fetching Products:', error.message);
        }
    };
    useEffect(() => {
        fetchInventory();
    }, []);



    // Function to handle submit
    const addInventory = async () => {
        const data = {
            products: inventory.map((item, index) => ({
                product_id: item._id, // Ensure product_id is included
                product_name: item.product_name,
                quantity: quantities[index] || 0, // Use quantity from state or default to 0
                unit: item.unit
            })),
            school_id: schoolId
        };
        setButtondisable(true);
        try {
            const response = await instance.post('Inventory/setInventory', data);
            if (response.data.success === true) {
                toast.success(response.data.message)
                fetchInventory();
                setButtondisable(false);
            } else {
                toast.error(response.data.message)
                setButtondisable(false);
            }

        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.error(error?.response?.data?.message);
            setButtondisable(false);
        }
    };


    return (
        <React.Fragment>
            <Topbar />
            <section className='main-1'>
                <div className='employees-text inventory-backtn'>
                    <div>
                        <Typography variant='h5'>Add Inventory</Typography> 
                        <Typography variant='h6' >School Name: <span>{schoolname}</span></Typography>
                        </div>
                    <Button onClick={() => navigate('/admin/schoollist')}><KeyboardBackspaceIcon />Back</Button>
                </div>
                <div className='employees-main inventory-main'>
                    <Grid container spacing={4}>
                        {inventory?.map((invent, index) => (
                            <React.Fragment key={invent.product_id}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            label='Product Name'
                                            type='text'
                                            fullWidth
                                            required
                                            value={invent.product_name}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            label='Current Stock'
                                            type='number'
                                            fullWidth
                                            required
                                            value={invent.quantity}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <div className='inventory-count'>
                                        <Typography>{invent.unit}</Typography>
                                        <Button className='count-minus' onClick={() => handleDecrement(index)}>-</Button>
                                        <TextField
                                            value={quantities[index] || 0} // Get the quantity for the current item or set it to 0
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (!isNaN(value) && value >= 0) {
                                                    const newQuantities = [...quantities];
                                                    newQuantities[index] = Number(value); // Update quantity at the current index
                                                    setQuantities(newQuantities); // Set the new quantities array in state
                                                }
                                            }}
                                        />
                                        <Button className='count-plus' onClick={() => handleIncrement(index)}>+</Button>
                                    </div>
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Grid item xs={12}>
                            <div className='add-inventory-btn'>
                                <Button 
                                disabled={buttondisable}
                                onClick={addInventory}
                                >Submit</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Inventory